'use strict';

const Events = require('minivents');
import { Router } from 'director/build/director';

(function() {
	const eventBus = new Events();

	const token = window.localStorage.getItem('token');
	if (!token) {
		window.location.replace('/');
	}

	const InstallController = function() {
		// Subscribe events.
		eventBus.on('receive-user-update', this._receiveUserUpdate, this);

		// Toggle instruction handlers.
		const toggleInstructions = (platform) => {
			document.querySelectorAll('.install-instructions').forEach((el) => {
				el.style.display = 'none';
			});
			document.getElementById(`install-instructions-${platform}`).style.display = 'block';
		};

		document.getElementById('install-squarespace').addEventListener('click', (e) => {
			e.preventDefault();
			toggleInstructions('squarespace');
		});

		document.getElementById('install-weebly').addEventListener('click', (e) => {
			e.preventDefault();
			toggleInstructions('weebly');
		});

		document.getElementById('install-wix').addEventListener('click', (e) => {
			e.preventDefault();
			toggleInstructions('wix');
		});

		document.getElementById('install-other').addEventListener('click', (e) => {
			e.preventDefault();
			toggleInstructions('other');
		});

		// Get user.
		qanda.shared.authRequest('GET', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/users', null, (httpRequest) => {
			const response = JSON.parse(httpRequest.responseText);
			eventBus.emit('receive-user-update', response.user);
		});
	};

	InstallController.prototype = {
		render: function() {
			showScreen('install');
		},

		_receiveUserUpdate: function(user) {
			document.querySelectorAll('.screen.install code > span').forEach((el) => {
				el.innerText = user.install_token;
			});
		},
	};

	const QuestionsController = function() {
		// Subscribe events.
		eventBus.on('receive-subscription-update', this._receiveSubscriptionUpdate, this);
		eventBus.on('receive-questions-update', this._receiveQuestionsUpdate, this);

		this._subscription = null;
		this._questions = [];
		this._selectedQuestionToken = null;

		this._questionCountEl = document.querySelector('#menu-questions > span');
		this._hideAnsweredEl = document.getElementById('hide-answered');
		this._addQuestionEl = document.getElementById('add-question');
		this._questionsEl = document.getElementById('questions');
		this._overlayEl = document.getElementById('question-overlay');
		this._closeEl = document.getElementById('question-overlay-popup-close');
		this._upgradeNotificationEl = document.getElementById('question-upgrade-notification');
		this._emailContainerEl = document.getElementById('question-email-container');
		this._emailEl = document.getElementById('question-email');
		this._questionViewEl = document.getElementById('question-view');
		this._questionEditEl = document.getElementById('question-edit');
		this._answerEl = document.getElementById('answer');
		this._showQuestionToggleEl = document.getElementById('show-question');
		this._sendResponseContainerEl = document.getElementById('send-response-container');
		this._sendResponseToggleEl = document.getElementById('send-response');
		this._deleteEl = document.getElementById('question-delete');
		this._saveEl = document.getElementById('question-save');

		this._hideAnsweredEl.addEventListener('click', (e) => {
			this._receiveQuestionsUpdate(this._questions);
		});

		this._addQuestionEl.addEventListener('click', (e) => {
			e.preventDefault();
			this._addQuestion();
		});

		this._questionsEl.addEventListener('click', (e) => {
			if (e.target.parentElement !== this._questionsEl) return;
			this._showQuestion(e.target.getAttribute('data-token'));
		});

		this._sendResponseToggleEl.addEventListener('click', this._toggleSendResponseHandler.bind(this));

		this._deleteEl.addEventListener('click', this._deleteHandler.bind(this));
		this._saveEl.addEventListener('click', this._saveHandler.bind(this));

		this._closeEl.addEventListener('click', (e) => {
			this._overlayEl.style.display = 'none';
		});

		this._getQuestions();
	};

	QuestionsController.prototype = {
		render: function() {
			showScreen('questions');
		},

		_getQuestions: function() {
			qanda.shared.authRequest('GET', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/questions', null, function(httpRequest) {
				const response = JSON.parse(httpRequest.responseText);
				eventBus.emit('receive-questions-update', response.questions);
			});
		},

		_receiveSubscriptionUpdate: function(subscription) {
			this._subscription = subscription;

			if (this._subscription === null || this._subscription.plan_type.id === 'standard') {
				this._upgradeNotificationEl.style.display = 'block';
			} else {
				this._upgradeNotificationEl.style.display = 'none';
			}
		},

		_receiveQuestionsUpdate: function(questions) {
			const hideAnswered = this._hideAnsweredEl.checked;
			let questionsCount = 0;
			this._questions = questions;
			this._questionsEl.innerHTML = '';
			this._questions.forEach((question) => {
				questionsCount += (question.answer ? 0 : 1);
				if (!question.answer || !hideAnswered) {
					const el = document.createElement('div');
					el.setAttribute('data-token', question.token);
					el.textContent = question.explanation;
					if (!question.answer) {
						el.classList.add('unanswered');
					}
					if (!!question.answer && !question.is_displayed) {
						el.classList.add('hidden');
					}
					this._questionsEl.appendChild(el);
				}
			});
			this._questionCountEl.textContent = `(${questionsCount})`;
		},

		_addQuestion: function() {
			this._selectedQuestionToken = null;
			this._emailContainerEl.style.display = 'none';
			this._questionEditEl.value = '';
			this._questionViewEl.style.display = 'none';
			this._questionEditEl.style.display = 'block';
			this._answerEl.value = '';
			this._showQuestionToggleEl.checked = true;
			this._sendResponseContainerEl.style.display = 'none';
			this._sendResponseToggleEl.checked = false;
			this._toggleSendResponseHandler();

			this._overlayEl.style.display = 'block';
		},

		_showQuestion: function(token) {
			const question = this._questions.find((question) => {
				return question.token === token;
			});
			if (!question) return;

			this._selectedQuestionToken = question.token;
			this._emailContainerEl.style.display = 'block';
			this._emailEl.textContent = question.email;
			this._questionViewEl.textContent = question.explanation;
			this._questionEditEl.value = question.explanation;
			this._answerEl.value = (question.answer || '');
			this._showQuestionToggleEl.checked = question.is_displayed;
			this._sendResponseContainerEl.style.display = 'block';
			this._sendResponseToggleEl.checked = (!question.answer);
			this._toggleSendResponseHandler();
			
			if (this._subscription === null || this._subscription.plan_type.id === 'standard') {
				this._questionViewEl.style.display = 'block';
				this._questionEditEl.style.display = 'none';
			} else {
				this._questionViewEl.style.display = 'none';
				this._questionEditEl.style.display = 'block';
			}

			this._overlayEl.style.display = 'block';
		},

		_toggleSendResponseHandler: function(e) {
			this._saveEl.textContent = ((this._sendResponseToggleEl.checked ? 'Send Response & ' : '') + 'Save');
		},

		_deleteHandler: function(e) {
			e.preventDefault();

			if (!window.confirm('Do you want to delete this question?')) {
				return;
			}

			// Delete the question.
			qanda.shared.blockedAuthRequest(
				this._deleteEl,
				this._deleteHandler,
				'DELETE',
				`https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/questions/${this._selectedQuestionToken}`,
				{
					onSuccess: (response) => {
						this._getQuestions();
						this._closeEl.click();
					},
					onFail: (error) => {
						alert(error);
					},
				}
			);
		},

		_saveHandler: function(e) {
			e.preventDefault();

			// Add or update question.
			let method = 'POST';
			let url = 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/questions';
			let explanation = this._questionEditEl.value;

			if (this._selectedQuestionToken !== null) {
				method = 'PUT';
				url = `https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/questions/${this._selectedQuestionToken}`;

				if (this._subscription === null || this._subscription.plan_type.id === 'standard') {
					explanation = this._questionViewEl.textContent;
				}
			}

			qanda.shared.blockedAuthRequest(this._saveEl, this._saveHandler, method, url, {
				body: {
					explanation: explanation,
					answer: this._answerEl.value,
					is_displayed: this._showQuestionToggleEl.checked,
					send_email: this._sendResponseToggleEl.checked,
				},
			}, (httpRequest) => {
				const response = JSON.parse(httpRequest.responseText);
				if (response.success) {
					this._getQuestions();
					this._closeEl.click();
				}
			});
		},
	};

	const CustomizeController = function() {
		// Subscribe events.
		eventBus.on('receive-user-update', this._receiveUserUpdate, this);

		this._previewLoaded = false;

		this._errorEl = document.getElementById('settings-error');
		this._backgroundColorEl = document.getElementById('settings-background-color');
		this._buttonColorEl = document.getElementById('settings-button-color');
		this._fontFamilyEl = document.getElementById('settings-font-family');
		this._allowQuestionsEl = document.getElementById('settings-allow-questions');
		this._saveEl = document.getElementById('settings-save');

		this._backgroundColorEl.addEventListener('change', this._updatePreviewHandler.bind(this));
		this._buttonColorEl.addEventListener('change', this._updatePreviewHandler.bind(this));
		this._fontFamilyEl.addEventListener('change', this._updatePreviewHandler.bind(this));
		this._allowQuestionsEl.addEventListener('click', this._updatePreviewHandler.bind(this));
		this._saveEl.addEventListener('click', this._saveHandler.bind(this));
	};

	CustomizeController.prototype = {
		render:  function() {
			showScreen('settings');
		},

		_receiveUserUpdate: function(user) {
			if (!this._previewLoaded) {
				const that = this;
				const script = document.createElement('script');
				script.id = 'qanda-script-v2';
				script.src = ('/plugin/v2/qanda.js?token=' + user.install_token + '&preview=1');
				script.addEventListener('qanda-ready', function(e) {
					that._iframeEl = document.querySelector('iframe');
				}, false);
				document.getElementById('script-container').appendChild(script);
				
				this._previewLoaded = true;
			}

			this._backgroundColorEl.jscolor.fromString(user.customizations.background_color);
			this._buttonColorEl.jscolor.fromString(user.customizations.button_color);
			this._fontFamilyEl.value = user.customizations.font_family;
			this._allowQuestionsEl.checked = user.customizations.can_ask_questions;
		},

		_updatePreviewHandler: function() {
			const message = {};
			if (this._backgroundColorEl.value) message.background_color = this._backgroundColorEl.value;
			if (this._buttonColorEl.value) message.button_color = this._buttonColorEl.value;
			if (this._fontFamilyEl.value) message.font_family = this._fontFamilyEl.value;
			message.can_ask_questions = this._allowQuestionsEl.checked;
			this._iframeEl.contentWindow.postMessage(message, '*');
		},

		_saveHandler: function(e) {
			e.preventDefault();

			// Update user.
			qanda.shared.blockedAuthRequest(
				this._saveEl,
				this._saveHandler,
				'PUT',
				'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/users',
				{
					body: {
						customizations: {
							background_color: this._backgroundColorEl.value,
							button_color: this._buttonColorEl.value,
							font_family: this._fontFamilyEl.value,
							can_ask_questions: this._allowQuestionsEl.checked,
						},
					},
					onSuccess: (response) => {
						this._errorEl.innerText = '';
						eventBus.emit('receive-user-update', response.user);
					},
					onFail: (error) => {
						this._errorEl.innerText = error;
					},
				}
			);
		},
	};

	const SubscriptionController = function() {
		// Subscribe events.
		eventBus.on('receive-subscription-update', this._receiveSubscriptionUpdate, this);
		eventBus.on('receive-user-update', this._receiveUserUpdate, this);

		this._subscription = null;
		this._user = null;

		this._subscriptionExpiredMessageEl = document.getElementById('subscription-expired-message');
		this._subscriptionTrialMessageEl = document.getElementById('subscription-trial-message');
		this._subscriptionTrialDaysEl = document.querySelector('#subscription-trial-message > strong');
		this._provideBillingEl = document.querySelector('#subscription-trial-message > a');
		this._errorEl = document.getElementById('subscription-error');
		this._standardButtonEl = document.getElementById('subscribe-standard-button');
		this._proButtonEl = document.getElementById('subscribe-pro-button');

		this._standardButtonEl.addEventListener('click', this._standardHandler.bind(this));
		this._proButtonEl.addEventListener('click', this._proHandler.bind(this));

		// Get subscription.
		qanda.shared.authRequest('GET', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/subscriptions', null, function(httpRequest) {
			const response = JSON.parse(httpRequest.responseText);
			eventBus.emit('receive-subscription-update', response.subscription);
		});

		this._stripeCheckout = StripeCheckout.configure({
			key: 'pk_live_Luk0iWXCp14OydvIgja9DDnm',
			image: '/images/app/logo@2x.png',
			name: 'Q&A Plugin',
			locale: 'auto',
			panelLabel: 'Subscribe',
			zipCode: true,
			token: (token) => {
				qanda.shared.authRequest('PUT', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/subscriptions', {
					body: {
						token: token,
					},
					onSuccess: (response) => {
						this._errorEl.innerText = '';
						eventBus.emit('receive-subscription-update', response.subscription);
					},
					onFail: (error) => {
						this._errorEl.innerText = error;
					},
				});
			}
		});
 
		// Close Checkout on page navigation:
		window.addEventListener('popstate', () => {
			this._stripeCheckout.close();
		});
	};

	SubscriptionController.prototype = {
		render: function() {
			showScreen('subscription');
		},

		_receiveSubscriptionUpdate: function(subscription) {
			this._subscription = subscription;

			if (this._subscription === null) {
				this._subscriptionExpiredMessageEl.style.display = 'block';
				this._subscriptionTrialMessageEl.style.display = 'none';
				this._standardButtonEl.innerText = 'Subscribe';
				this._proButtonEl.innerText = 'Subscribe';
				return;
			}

			this._subscriptionExpiredMessageEl.style.display = 'none';
			this._subscriptionTrialMessageEl.style.display = (
				this._subscription.is_free_trial && !this._subscription.has_billing_information
				? 'block'
				: 'none'
			);
			this._subscriptionTrialDaysEl.innerText = this._subscription.trial_days_remaining;

			if (this._subscription.plan_type.id === 'standard') {
				this._standardButtonEl.innerText = 'Cancel';
				this._proButtonEl.innerText = 'Upgrade';
			} else {
				this._standardButtonEl.innerText = 'Downgrade';
				this._proButtonEl.innerText = 'Cancel';
			}

			const el = this._provideBillingEl.cloneNode(true);
			el.addEventListener('click', (e) => {
				e.preventDefault();
				// Open Checkout with further options:
				this._stripeCheckout.open({
					description: this._subscription.plan_type.name,
					amount: this._subscription.plan_type.price_in_cents_usd,
					email: this._user.email,
				});
			});
			this._provideBillingEl.parentNode.replaceChild(el, this._provideBillingEl);
			this._provideBillingEl = el;
		},

		_receiveUserUpdate: function(user) {
			this._user = user;
		},

		_standardHandler: function(e) {
			e.preventDefault();

			if (this._subscription && this._subscription.plan_type.id === 'standard') {
				this._cancelSubscription(this._standardButtonEl, this._standardHandler);
				return;
			}
			
			this._subscribe('standard', this._standardButtonEl, this._standardHandler);
		},

		_proHandler: function(e) {
			e.preventDefault();

			if (this._subscription && this._subscription.plan_type.id === 'pro') {
				this._cancelSubscription(this._proButtonEl, this._proHandler);
				return;
			}

			this._subscribe('pro', this._proButtonEl, this._proHandler);
		},

		_cancelSubscription: function(el, handler) {
			// Cancel subscription.
			qanda.shared.blockedAuthRequest(
				el,
				handler,
				'DELETE',
				`https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/subscriptions`,
				{
					onSuccess: (response) => {
						this._errorEl.innerText = '';
						eventBus.emit('receive-subscription-update', null);
					},
					onFail: (error) => {
						this._errorEl.innerText = error;
					},
				}
			);
		},

		_subscribe: function(planType, el, handler) {
			// Subscribe to plan.
			qanda.shared.blockedAuthRequest(
				el,
				handler,
				'POST',
				`https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/subscriptions`,
				{
					body: {
						plan_type: planType,
					},
					onSuccess: (response) => {
						this._errorEl.innerText = '';
						eventBus.emit('receive-subscription-update', response.subscription);
					},
					onFail: (error) => {
						this._errorEl.innerText = error;
					},
				}
			);
		},
	};

	const AccountController = function() {
		// Subscribe events.
		eventBus.on('receive-user-update', this._receiveUserUpdate, this);

		this._errorEl = document.getElementById('account-error');
		this._emailEl = document.getElementById('account-email');
		this._organizationEl = document.getElementById('account-organization');
		this._currentPasswordEl = document.getElementById('account-current-password');
		this._newPasswordEl = document.getElementById('account-new-password');
		this._saveEl = document.getElementById('account-save');

		this._saveEl.addEventListener('click', this._saveHandler.bind(this));
	};

	AccountController.prototype = {
		render: function() {
			showScreen('account');
		},

		_receiveUserUpdate: function(user) {
			this._emailEl.innerText = user.email;
			this._organizationEl.value = user.organization;
		},

		_saveHandler: function(e) {
			e.preventDefault();

			// Update user.
			qanda.shared.blockedAuthRequest(
				this._saveEl,
				this._saveHandler,
				'PUT',
				'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/users',
				{
					body: {
						organization: this._organizationEl.value,
						current_password: this._currentPasswordEl.value,
						new_password: this._newPasswordEl.value,
					},
					onSuccess: (response) => {
						this._errorEl.innerText = '';
						this._currentPasswordEl.value = '';
						this._newPasswordEl.value = '';
						eventBus.emit('receive-user-update', response.user);
					},
					onFail: (error) => {
						this._errorEl.innerText = error;
					},
				}
			);
		},
	};

	const menuEls = document.querySelectorAll('.menu a');
	const screenEls = document.querySelectorAll('.screen');
	function showScreen(screen) {
		menuEls.forEach((el) => {
			el.classList.remove('selected');
		});
		document.getElementById('menu-' + screen).classList.add('selected');

		screenEls.forEach((el) => {
			if (el.classList.contains(screen)) {
				el.style.display = 'block';
			} else {
				el.style.display = 'none';
			}
		});
	}

	const installController = new InstallController();
	const customizeController = new CustomizeController();
	const questionsController = new QuestionsController();
	const subscriptionController = new SubscriptionController();
	const accountController = new AccountController();

	const router = Router({
		'/install': () => { installController.render() },
		'/customize': () => { customizeController.render() },
		'/questions': () => { questionsController.render() },
		'/subscription': () => { subscriptionController.render() },
		'/account': () => { accountController.render() },
	});

	router.init('install');

	document.getElementById('menu-install').addEventListener('click', (e) => {
		e.preventDefault();
		window.location.hash = '#/install';
	});

	document.getElementById('menu-settings').addEventListener('click', (e) => {
		e.preventDefault();
		window.location.hash = '#/customize';
	});

	document.getElementById('menu-questions').addEventListener('click', (e) => {
		e.preventDefault();
		window.location.hash = '#/questions';
	});

	document.getElementById('menu-subscription').addEventListener('click', (e) => {
		e.preventDefault();
		window.location.hash = '#/subscription';
	});

	document.getElementById('menu-account').addEventListener('click', (e) => {
		e.preventDefault();
		window.location.hash = '#/account';
	});

	document.getElementById('menu-logout').addEventListener('click', (e) => {
		e.preventDefault();
		window.localStorage.clear();
		window.location.replace('/');
	});
})();